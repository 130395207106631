header {
z-index: 10;
position: fixed;
width: 100%;

@media (max-width: 600px){
    width: 100vw;
}
}

.header {

    &__row {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;
        margin: 0px;
        transition: 0.3s;
        border-bottom: 1px solid $white;

        &.active, &.active-toggle, &.active-default {
            background: $white;
            border-bottom: none;
        }

        &-inner {
            position: absolute;
            right: 0;

            &-section {
                border-left: 1px solid $white;
                transition: 0.3s;
                color: $white;
                span {
                    color: $white;
                }

                &.active {
                    border-left: 1px solid $secondary;
                }
            }
        }
    }

    &__nav {
        top: 0;
        left: 0;
        height: 100%;
        position: relative;
        background:rgba(53, 58, 56, 0.9);
        
        @media(max-width: 991px){
            overflow: scroll;
        }



        @media (max-width: 991px){
            background:rgba(53, 58, 56);
            height: 100%;
        }

        &-wrapper {
            @media (min-width: 991px){
                width: 95%;
            }

            @media (min-width: 1570px){
                width: 55%;
            }
        }

        &-row {
            width: 75%;
            margin: auto;
            
            @media (max-width: 991px){
                width: 90%;
            }
        }

        &-menu {
            position: relative;
            list-style: none;
            padding-top: 20px;
            padding-left: 0px;
            margin-bottom: 0px;

            li {
            
                a{
                    color: $white;
                    font-size: 34px;
                    line-height: 50px;
                    text-decoration: none;

                    @media (max-width: 991px){
                        font-size: 20px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        &-line {
            @media (min-width: 991px){
                border-right: 1px solid $white;
            }
        }
    }

    &__mobile-links {
        width: 50%;
        display: flex;
        justify-content: space-around;
        margin: auto;
        position: absolute;
        bottom: 90px;

        @media (max-width: 500px){

            width: 100%;
            padding-left: 0px;
            padding-bottom: 0px;

            a {
                width: 25vw;
                border: 1px solid $white;
                height: 25vw;
                display: flex;
            }

            img {
                height: 25px;
                margin: auto;
            }
        }

        @media only screen and (max-height: 450px) and (max-width: 991px) and (orientation: landscape) {
            padding: 20px;
            height: 80%;
            width: 50vw;
            bottom: auto;
        }

        @media (min-width: 991px){
            display: none;
        }

        @media (min-width: 500px) and (max-width: 991px){
            padding-left: 30px;
            padding-bottom: 30px;

            img {
                height: 25px;
            }
        }
    }

    &__logo path {
        transition: fill 0.3s;
    }

    &__logo-section {
        padding-left: 1vw;
    }

    &__notification {
        background-color: $secondary;
        padding: 10px;
        text-align: center;

        a {
            color: $white;
            text-decoration: underline;
        }
    }
}

.active .header__logo path,
.active-toggle .header__logo path,
.active-default .header__logo path {
    fill: $secondary;
}

.header__row-inner-section.active,
.header__row-inner-section.active span {
    color: $secondary;
}



.menu-toggle {
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.3s;
    height: 57px;

    &:hover, &.active {
        background-color: $primary;
        color: $white !important;
        span {
            color: $white !important;
        }
    }

    &-active {
        background-color: $primary;
        color: $white !important;
        span {
            color: $white !important;
        }
    }
}

.active-toggle,
.active-default {
    
    & .header__row-inner-section {
        border-left: 1px solid $secondary;
        color: $secondary;
        & span {
            color: $secondary;
        }
    }

}

.hamburger-box {
    width: 25px;
    height: 28px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 25px;
    height: 1px;
    background-color: $white;
}

.hamburger-inner::before {
    top: -8px;
}

.hamburger-inner::after {
    bottom: -8px;
}

.header__row-inner-section.active .hamburger-inner,
.header__row-inner-section.active .hamburger-inner::before,
.header__row-inner-section.active .hamburger-inner::after,
.active-default .hamburger-inner,
.active-default .hamburger-inner::before,
.active-default .hamburger-inner::after {
    background-color: $secondary;
}

.mobile-menu-header {
    display: none;

    @media (min-height: 600px){
        display: block;
        margin-top: 30px;
    }

    @media (min-width: 991px){
        margin-top: 0px;
    }
}