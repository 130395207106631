.contact {

    label {
        width: 100%;
    }

    input {
        border: 1px solid #E4E4E4;
    }

    .wpcf7-text,
    .wpcf7-textarea {
        width: 100%;
        border: 1px solid #E4E4E4;
        min-height: 45px;
        margin-top: 5px;
    }

    .wpcf7-list-item {
        margin-left: 0px;
    }

}