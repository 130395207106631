.case-study {

    &__heading {
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        color: #3CD19C;
    }

    &__image {
        max-width: 435px;
        height: 270px;
        background-position: center;
        background-size: cover;

        @media (max-width: 991px){
            height: 200px;
        }
    }

    &__image-col {
        order: 1;
    }

    &__info-col {
        order: 2;
    }

}

@media (min-width: 991px){
    
    .case-study:nth-child(even) .case-study__image-col {
        order: 2;
    }

    .case-study:nth-child(even) .case-study__info-col {
        order: 1;
    }

}