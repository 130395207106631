.client{

    &__image {
        border: 0.5px solid #979797;
        overflow: hidden;
        height: 140px;
        width: 230px;
        margin: auto;
    }

    &__image-large {
        width: 280px;
        height: 190px;
        overflow: hidden;
        border: none;
        margin: 20px;
    }

}