.home {

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, auto);
        overflow: hidden;

        @media (max-width: 991px){
            grid-template-columns: repeat(2, auto);
        }
        
        &-cell {
            height: 480px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            overflow: hidden;
            transition: all 0.3s;
            filter: brightness(60%);
            width: 100%;
            object-fit: cover;

            &:hover {
                transform: scale(1.2);
                filter: brightness(40%);
            }

            @media (max-width: 991px){
                height: 190px;
            }
        }
    
        &-heading {
            position: absolute;
            bottom: 40px;
            left: 40px;
            color: $white;  

            @media (max-width: 576px){
                font-size: 18px;
                bottom: 10px;
                left: 10px;
            }
        }
     
    }

    &__case-study {

        @media (max-width: 991px){
            font-size: 14px;
        }

        img {
            width: 100%;
            height: 285px;
            object-fit: cover;

            @media (max-width: 1440px){
                height: 220px;
            }
        }

        h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            color: #434B47;
            @media (max-width: 768px){
                font-size: 22px;
            }
        }

        a {
            text-decoration: underline;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
        }

        &-mobile-excerpt{
            @media (max-width: 991px){
            height: 190px;
          }
        }

    }

    &__carousel {

        &-container {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 100vh;

            @media only screen and (max-height: 450px) and (max-width: 991px) and (orientation: landscape) {
                height: 80vw;
            }
        }

        img {
            width: 100%;
            height: 100%;
            filter: brightness(40%);
            object-fit: cover;
        }

        &-list {
            position: absolute;
            transform: translateY(-100%);
            font-size: 38px;
            color: $white;
            z-index: 5;
            cursor: pointer;
            padding: 50px;
            padding-bottom: 80px;
            width: 551px;

            @media (max-width: 767px){
                padding: 20px;
                padding-bottom: 70px;
                padding-left: 30px;
                font-size: 26px;
                width: 370px;
            }

            @media (max-width: 330px){
                width: auto;
            }
            
            &-item {
                display: flex;

                a {
                    color: $white;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }

                &.active {
                    font-weight: bold;
                }
            }
        }

        &-link {
            display: none;
            margin-left: 10px;

            &:hover {
                    filter: brightness(60%);
                }
            }
    }

}

.active .home__carousel-link {
    display: block;
}


.home-pagination {
        bottom: 30px !important;
        width: auto !important;
        left: 30px !important;
        right: auto !important;

        @media (min-width: 768px){
            left: 50px !important;
        }

        @media (max-width: 768px){
            bottom: 50px !important;
        }

    .swiper-pagination-bullet {
        border-radius: 0px;
        width: 20px;
        border: 1px solid $primary;
        background: none;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        border-radius: 0px;
        width: 20px;
        border: 1px solid $primary;
        background: $primary;
        opacity: 1
    }
}

.case-study-pagination {
    .swiper-pagination-bullet {
        border-radius: 0px;
        width: 20px;
        border: 1px solid $primary;
        background: none;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        border-radius: 0px;
        width: 20px;
        border: 1px solid $primary;
        background: $primary;
        opacity: 1
    }
}