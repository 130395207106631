.about {

    &__left-text {
        
        p {
            font-size: 28px;
            line-height: 44px;
           
        }

        @media (min-width: 991px) {
            p {
                width: 95%;
                font-size: 38px;
            }
        }

    }

    &__right-text {
        font-size: 16px;
        color: $darkgrey;
    }

}