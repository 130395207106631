
body {
    color: $secondary;
    font-size: 16px;
    font-family: Gothic;
    width: 100% !important;
    font-display: swap;
}

.text-white {
    color: $white;
}

.text-green {
    color: $primary !important;
}

.section-green {
    background-color: $primary;
    color: $white;
}

.section-grey {
    background-color: $lightgrey;
}

.section-white {
    background-color: $white;
}


.btn {
    border: 1px solid $white;
    border-radius: 25px;
    color: $white;
    padding: 8px 25px 6px 25px;
    text-transform: uppercase;
    text-decoration: none;
    transition-duration: 0.3s;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px; 

    @media (min-width: 556px){
        max-width: 200px;
    }

    &:hover {
        background-color: $white;
        color: $darkgrey;
        border: 1px solid $primary;
        text-decoration: none;
    
    }

    &__white {
        color: $darkgrey;
        background-color: $white;
        
        &:hover {
            color: $white;
            background: $primary;
            border: 1px solid $white;
        }
    }

    &__green {
        color: $primary;
        border: 1px solid $primary;
        
        &:hover {
            color: $white;
            background: $primary;
            border: 1px solid $white;
        }
    }

    &__black {
        color: $white;
        border: 1px solid $white;
        background-color: #353A38;
        font-weight: normal;
        padding: 10px 25px;
    }

}


.page {
   
    &__header,
    &__content h3 {
     font-size: 16px;
     text-decoration: underline;
     color: $darkgrey;
     font-weight: bold;
     text-transform: uppercase;
     }
    
 }



.default {

    &__banner {
        height: 550px;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center !important;
        position: relative;

        @media (max-width: 600px){
            height: 100vw;
        }
    
        &::before {
            content: "";
            background: rgba(black, 0.6);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        &-inner {
            width: 100%;
            height: 100%;
            display: flex;
            position: relative;
        }
    
        &-title {
            margin: auto;
            opacity: 1;
            text-align: center;
            max-width: 800px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @media (max-width: 768px){
                padding-top: 65px;
            }
            
            h1, h2 {
                font-weight: bold;
                font-size: 64px;
                color: $white;
                text-decoration: none;

                @media (max-width: 600px){
                    font-size: 36px;
                    color: $white;
                }
            }

            h5 {
                color: $primary;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: underline;
                text-align: center;
            }
        }
    }
}

.swiper-button-next {
    background-image: url(../img/slider-left.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    left: auto;
    right: -65px;
    z-index: 1;

    @media (max-width: 768px){
        display: none;
    }

    @media (max-width: 1200px){
        right: -40px;
    }
    
    &:after {
        content: none;
    }

    &:hover {
        filter: brightness(80%);
    }
}

.swiper-button-prev {
    background-image: url(../img/slider-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    left: -65px;
    right: auto;
    z-index: 1;

    @media (max-width: 768px){
        display: none;
    }

    @media (max-width: 1200px){
        left: -40px;
    }

    &:after {
        content: none;
    }

    &:hover {
        filter: brightness(80%);
    }
}

.image-plus-link {
    color: $white;
    text-decoration: underline;
}

.page-top-padding {
    padding-top: calc(3rem + 50px);
}

.swiper-wrapper {
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

         
@-webkit-keyframes fadeInDown {
    0% {
       opacity: 0;
       -webkit-transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateY(0);
    }
 }
 
 @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }
 
 .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
 }

 #menuListEvents,
 #menuListCompany {
     animation-duration: 0.3s;
 }


 p:empty { display:none; }