.events {

    &__gallery {

        &-grid {
            display: grid;
            grid-template-columns: auto;
            overflow: hidden;
    
            @media (min-width: 768px){
                grid-template-columns: repeat(2, 50%);
            }

            @media (min-width: 991px){
                grid-template-columns: repeat(3, 33.3%);
            }
        }

        &-cell {
            max-height: 325px;
            max-width: 440px;
            margin: auto;
            overflow: hidden;

            @media (min-width: 768px){
                max-width: 100%;
            }

            @media (max-width: 768px){
                max-height: 230px;
            }
            
            img {
                min-height: 325px;
                min-width: 440px;
                border: 1px solid $lightgrey;
                border-bottom: none;
                object-fit: cover;

                @media (max-width: 767px){
                    min-height: 230px;
                    min-width: auto;
                    display: inline;
                }
            }
        }

        &-header {
            display: flex;
            justify-content: space-between;
            padding: 0px 20px;
        }

        &-wrapper {
            @media (max-width: 991px){
                padding-bottom: 25px;
            }
        }
    }
}


.gallery-button-next {
    background-image: url(../img/slider-left.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    left: auto;
    right: -65px;
    cursor: pointer;

    @media (max-width: 767px){
        display: none;
    }
    
    &:after {
        content: none;
    }
}

.gallery-button-prev {
    background-image: url(../img/slider-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    left: -65px;
    right: auto;
    cursor: pointer;

    @media (max-width: 767px){
        display: none;
    }

    &:after {
        content: none;
    }
    
}

.gallery-pagination {
    position: absolute;
    bottom: 0px;

    .swiper-pagination-bullet {
        border-radius: 0px;
        width: 20px;
        border: 1px solid $primary;
        background: none;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        border-radius: 0px;
        width: 20px;
        border: 1px solid $primary;
        background: $primary;
        opacity: 1
    }
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(1);
    opacity: 0.7;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(1);
    opacity: 0.5;
}