
$primary: #3CD19C;
$secondary: #353A38;
$darkgrey: #434B47;
$lightgrey: #F2F2F2;
$white: white;
$black: black;

@font-face {
    font-family: Gothic;
    src: url(../fonts/gothicA1-Regular.ttf);
    font-display: swap;
  }

  @font-face {
    font-family: Gothic;
    src: url(../fonts/gothicA1-Bold.ttf);
    font-weight: bold;
    font-display: swap;
  }



  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
  ); 
   
  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1340px
  ); 

  $hamburger-layer-width: 30px !default;
  $hamburger-padding-x: 0px !default;
  $hamburger-padding-y: 0px !default;