footer {
    background-color: $secondary;

    .footer {
        &__link,
        &__link:hover {
            text-decoration: none;
            color: $white;
        }

        &__contacts a {
            color: $white;
            font-weight: bold;
        }

        &__contacts img {
            height: 18px;
        }

        &__phone-icon {
            width: 40px;
            height: 26px !important;
        }

        &__logo {
            height: 25px;

            @media (min-width: 768px){
                height: 39px;
            }
        }
    }
}

.to-top {
    padding: 10px 18px;
    background: #3CD19C;
    border-radius: 30px;
    position: absolute;
    right: 30px;
    cursor: pointer;

    &:hover {
        filter: brightness(80%);
    }

    span {
        color: white;
    }
}